import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"

import { Header, Content, Map } from "page_components/portfolio"

const Portfolio = ({ data, location }) => {
  const title = "Portfolio"
  const allPortfolioCommercial = data.allWpPortfolioCommercial.nodes
  const [grid, setGrid] = useState("grid")

  const portfolioData = {
    grid,
    setGrid,
  }

  return (
    <Layout
      location={location}
      seo={{
        title: "Zrealizowane inwestycje",
        description:
          "Zobacz nasze realizacje mieszkaniowe oraz komercyjne i przekonaj się dlaczego mamy tak dobre opinie wśród Klientów.",
      }}
    >
      <Breadcrumbs title={title} />
      <Header {...portfolioData} />
      {grid === "grid" ? (
        <Content allPortfolioCommercial={allPortfolioCommercial} />
      ) : (
        <Map allPortfolioCommercial={allPortfolioCommercial} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPortfolioCommercial(
      filter: { language: { code: { eq: PL } } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        title
        uri
        slug
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        acfPortfolioCommercial {
          city
          area
          map {
            latitude
            longitude
          }
        }
      }
    }
  }
`

export default Portfolio
